import React from 'react';
import { useParams } from 'react-router-dom';
import NFTCreator from '../components/NFTCreator';

const ProjectPage = () => {
  const { project } = useParams();
  let projectData;

  try {
    projectData = require(`../projects/generator/${project}.json`);
  } catch (error) {
    return <div>Project not found</div>;
  }

  return <NFTCreator projectData={projectData} />;
};

export default ProjectPage;
