import React from 'react';

const Homepage = () => {
  return (
    <div>
      <div className="aximo-preloader-wrap">
        <div className="aximo-preloader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <header
        className="site-header aximo-header-section aximo-header1 dark-bg"
        id="sticky-menu"
      >
        <div className="container">
          <nav className="navbar site-navbar">
            <div className="brand-logo">
              <a href="/">
                <img
                  src="assets/images/meme-logo.svg"
                  alt=""
                  className="light-version-logo"
                />
              </a>
            </div>
          
            <div className="header-btn header-btn-l1 ms-auto d-xs-inline-flex">
              <a
                className="aximo-default-btn pill aximo-header-btn"
                href="#contact"
              >
                Contact Us
              </a>
            </div>
           
          </nav>
        </div>
      </header>

      <div className="aximo-hero-section dark-bg">
        <div className="container position-relative">
          <div className="row">
            <div className="col-lg-8">
              <div className="aximo-hero-content">
                <h1>
                  <span className="aximo-title-animation">
                    Get Meme
                    <img src="assets/images/star2.png" alt="" />
                  </span>
                    Coin Lab
                </h1>
                <p>
                Unleash your inner comedian with Meme Coin Lab's powerful meme generator. Perfect for creators, Meme Coin Lab allows you to embed our cutting-edge meme generator directly into your own website, providing your audience with the tools they need to craft hilarious and shareable memes.
                </p>
               
                <a className="aximo-call-btn" href="#demo">
                  Check Sample Project <i className="fa fa-arrow-down" />
                </a>
                <div className="aximo-hero-shape">
                  <img src="assets/images/shape1.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="aximo-hero-thumb wow fadeInRight" data-wow-delay="0s">
                <img src="assets/images/meme-vegan.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='demo'>
    
        <iframe id="iframe" src="./vegan-monsters" title="Meme Coin Lab" ></iframe>
      </div>

      <div className="aximo-auto-slider-section">
        <div className="swiper aximo-auto-slider">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="aximo-auto-slider-item">
                <h3>Meme Coin Lab</h3>
                <img src="assets/images/star2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

    
      <div className="section py-5">
        <div className="container">
          <div className="aximo-pricing-title">
            <h2>
              Our Sample Project
            </h2>
          </div>
        
          <div className="row justify-content-center" >
            <div className="col-xl-4 col-md-6">
              <div
                className="aximo-pricing-wrap2 wow fadeInUpX"
                data-wow-delay="0.1s"
              >
                <div className="aximo-pricing-header2">
                  <h5>Meme PFP Generator</h5>
                </div>
              
                <div className="aximo-pricing-description pt-0 ">
                  <p>
                  A user-friendly tool to generate images from your NFT layers. Perfect for creating and customizing unique PFP profiles with ease. Ideal for showcasing your personal style  in the NFT community.                  </p>
                </div>

                <a className="aximo-pricing-btn2  mt-4" href="/vegan-monsters" target='_blank'>
                  Visit Demo
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="aximo-pricing-wrap2 wow fadeInUpX"
                data-wow-delay="0.1s"
              >
                <div className="aximo-pricing-header2">
                  <h5>Meme Maker</h5>
                </div>
              
                <div className="aximo-pricing-description pt-0 ">
                  <p>
                  An intuitive platform for designing your own memes with customizable text and images from your Meme Coin. Perfect for expressing creativity and sharing laughs online.
                  </p>
                </div>

                <a className="aximo-pricing-btn2 mt-4" href="/vegan-monsters" target='_blank'>
                  Visit Demo
                </a>
              </div>
            </div>
          
    </div>
        </div>
      </div>


      <footer className="aximo-footer-section dark-bg" id='contact'>
        <div className="container">
          <div className="aximo-footer-top aximo-section-padding py-5">
            <div className="row">
              <div className="col-lg-7 m-auto text-center">
                <div className="aximo-default-content light position-relative">
                  <img src="assets/images/meme-logo.svg" className='mb-3' alt="meme coin lab" height={90}/>

                  <h2>
                    <span className="aximo-title-animation">
                      Let's start a 
                      <span className="aximo-title-icon">
                        <img src="assets/images/star.png" alt="" />
                      </span>
                    </span>
                    project together
                  </h2>
                  <p>
                    We work closely with our clients to understand their objectives,
                    target audience, and unique needs. We use our creative skills to
                    translate these requirements and practical design solutions.
                  </p>

                  <div className="aximo-social-icon social-large">
                    <ul>
                      <li>
                        <a href="https://x.com/wekupneo" target="_blank" rel="noreferrer">
                          <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/wekupneo" target="_blank" rel="noreferrer">
                          <svg
                              width="24px"
                              height="24px"
                              version="1.1"
                              style={{
                                fillRule: "evenodd",
                                clipRule: "evenodd",
                                strokeLinejoin: "round",
                                strokeMiterlimit: "1.41421"
                              }}
                            >
                              <path
                                id="telegram-1"
                                d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"
                              />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://devcabal.xyz/" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm10 12c0 .685-.07 1.354-.202 2h-3.853c.121-1.283.129-2.621 0-4h3.853c.132.646.202 1.315.202 2zm-.841-4h-3.5c-.383-1.96-1.052-3.751-1.948-5.278 2.435.977 4.397 2.882 5.448 5.278zm-5.554 0h-2.605v-5.658c1.215 1.46 2.117 3.41 2.605 5.658zm-4.605-5.658v5.658h-2.605c.488-2.248 1.39-4.198 2.605-5.658zm0 7.658v4h-2.93c-.146-1.421-.146-2.577 0-4h2.93zm0 6v5.658c-1.215-1.46-2.117-3.41-2.605-5.658h2.605zm2 5.658v-5.658h2.605c-.488 2.248-1.39 4.198-2.605 5.658zm0-7.658v-4h2.93c.146 1.421.146 2.577 0 4h-2.93zm-4.711-11.278c-.896 1.527-1.565 3.318-1.948 5.278h-3.5c1.051-2.396 3.013-4.301 5.448-5.278zm-6.087 7.278h3.853c-.121 1.283-.129 2.621 0 4h-3.853c-.132-.646-.202-1.315-.202-2s.07-1.354.202-2zm.639 6h3.5c.383 1.96 1.052 3.751 1.948 5.278-2.435-.977-4.397-2.882-5.448-5.278zm12.87 5.278c.896-1.527 1.565-3.318 1.948-5.278h3.5c-1.051 2.396-3.013 4.301-5.448 5.278z"/></svg>
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                
                </div>
              </div>
            
            </div>
          </div>
          <div className="aximo-footer-bottom">
            <div className="aximo-copywright one text-center">
              <p> © Copyright 2024. All Rights Reserved by DevCabal</p>
            </div>
          </div>
        </div>
      </footer>

    </div>
  );
};

export default Homepage;
