import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

const NFTCreator = ({ projectData }) => {
  const { name, description, parts } = projectData;
  const [selectedParts, setSelectedParts] = useState({});
  const [partsList, setPartsList] = useState([]);
  const canvasRef = useRef(null);

  const canvasWidth = 1024;
  const canvasHeight = 1024;

  useEffect(() => {
    const partsWithIds = parts.map((part, index) => ({ id: index + 1, ...part }));
    setPartsList(partsWithIds);
  }, [parts]);

  const groupedParts = partsList.reduce((acc, part) => {
    if (!acc[part.category]) {
      acc[part.category] = [];
    }
    acc[part.category].push(part);
    return acc;
  }, {});

  const getDefaultPartId = useCallback((category) => {
    const defaultPart = partsList.find((part) => part.category === category && part.name === 'Default');
    return defaultPart ? defaultPart.id : defaultPart;
  }, [partsList]);

  const drawPart = useCallback((ctx, partId, canvas) => {
    return new Promise((resolve, reject) => {
      const part = partsList.find((p) => p.id === partId);
      if (part) {
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
          resolve();
        };
        img.onerror = reject;
        img.src = part.icon;
      } else {
        resolve();
      }
    });
  }, [partsList]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = canvasWidth * window.devicePixelRatio;
    canvas.height = canvasHeight * window.devicePixelRatio;

    ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    Object.keys(groupedParts).forEach(async (category) => {
      const selectedPartId = selectedParts[category];
      const partIdToDraw = selectedPartId ? selectedPartId : getDefaultPartId(category);
      await drawPart(ctx, partIdToDraw, canvas);
    });
  }, [selectedParts, groupedParts, getDefaultPartId, drawPart]);

  const handlePartSelection = (partId, category) => {
    setSelectedParts((prevState) => ({
      ...prevState,
      [category]: partId,
    }));
  };

  const handleGenerateRandom = async () => {
    const randomSelectedParts = {};

    await Promise.all(Object.keys(groupedParts).map(async (category) => {
      const randomId = getRandomPartId(category);
      randomSelectedParts[category] = randomId;
    }));

    setSelectedParts(randomSelectedParts);
  };

  const getRandomPartId = (category) => {
    const partsInCategory = partsList.filter((part) => part.category === category);
    const randomIndex = Math.floor(Math.random() * partsInCategory.length);
    return partsInCategory[randomIndex].id;
  };

  const handleReset = () => {
    setSelectedParts({});
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;

    if (!canvas) {
      console.error('Canvas element not found');
      return;
    }

    const link = document.createElement('a');
    link.download = `${name}.png`;

    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvasWidth;
    tempCanvas.height = canvasHeight;
    const tempCtx = tempCanvas.getContext('2d');
    tempCtx.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height);

    link.href = tempCanvas.toDataURL('image/png');
    link.click();
  };

  const generateSliderSettings = (category) => ({
    slidesPerView: 7,
    spaceBetween: 5,
    navigation: {
      nextEl: `.swiper-button-next-${category}`,
      prevEl: `.swiper-button-prev-${category}`,
    },
    pagination: false,
    breakpoints: {
      320: {
        slidesPerView: 3,
        spaceBetween: 5,
      },
      450: {
        slidesPerView: 4,
        spaceBetween: 5,
      },
      600: {
        slidesPerView: 5,
        spaceBetween: 5,
      },
      768: {
        slidesPerView: 6,
        spaceBetween: 5,
      },
      1025: {
        slidesPerView: 7,
        spaceBetween: 5,
      },
    },
    modules: [Navigation, Pagination],
  });

  return (
    <div className="container py-5">
        <div className="row">
          <div className="col-lg-5 mb-4 mb-lg-0">
            <div
              className="aximo-iconbox-wrap wow fadeInUpX"
              data-wow-delay="0.1s"
            >
              <div className="canvas-wrapper">
                <canvas
                  className="nft-canvas"
                  ref={canvasRef}
                  width="1024"
                  height="1024"
                ></canvas>
              </div>

              <div className="buttons mt-3">
                <button className="aximo-pricing-btn2 " onClick={handleReset}>
                  Reset {name}
                </button>
                <button className="aximo-pricing-btn2 " onClick={handleGenerateRandom}>
                  Generate Random
                </button>
                <button className="aximo-pricing-btn2 " onClick={handleDownload}>
                  Download
                </button>
              </div>
            
            </div>
          </div>
          <div className="col-lg-7">
            <div
              className="aximo-iconbox-wrap wow fadeInUpX"
              data-wow-delay="0.2s"
            >
              <div className="parts-list">
                <h3 className="mb-3">Create Your {name}</h3>
                
                <p>{description}</p>
                {Object.keys(groupedParts).map((category) => (
                  <div key={category} className="category-section">
                    <h4 className="category-title">{category}</h4>
                    <Swiper {...generateSliderSettings(category)}>
                      {groupedParts[category].map((part) => (
                        <SwiperSlide key={part.id}>
                          <div
                            className={`part ${selectedParts[category] === part.id ? 'selected' : ''}`}
                            onClick={() => handlePartSelection(part.id, category)}
                          >
                            <img src={part.icon} alt={part.name} className="img-fluid" loading="lazy" />
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className={`swiper-button-prev swiper-button-prev-${category}`}>
                        <i className="fas fa-angle-left arrow"></i>
                      </div>
                      <div className={`swiper-button-next swiper-button-next-${category}`}>
                        <i className="fas fa-angle-right arrow"></i>
                      </div>
                    </Swiper>
                  </div>
                ))}
              </div>
              
            </div>
          </div>
          
        </div>
    </div>
  );
};

export default NFTCreator;
